<template>
  <div>
    <section
      v-loading="loading"
      element-loading-text="Gathering Data..."
    >
      <el-row :gutter="12">
        <el-col :span="21" style="padding: 10px">
          <b style="padding-left: 10px">{{ componentData.component_name }}</b>
        </el-col>
        <el-col :span="1">
          <el-dropdown
            trigger="click"
            v-if="!hide_options && isExpandTable === false"
          >
            <el-button
              icon="el-icon-more"
              type="text"
              class="icon-style mr-1"
            ></el-button>

            <el-dropdown-menu slot="dropdown">
              <a @click="editDialogBox">
                <el-dropdown-item>Edit</el-dropdown-item>
              </a>
              <a @click="deleteComponent">
                <el-dropdown-item>Delete</el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown
            trigger="click"
            v-if="!hide_options && isExpandTable === true"
          >
            <el-button icon="el-icon-more" type="text"></el-button>

            <el-dropdown-menu slot="dropdown">
              <a @click="editDialogBox">
                <el-dropdown-item>Edit</el-dropdown-item>
              </a>
              <a @click="deleteComponent">
                <el-dropdown-item>Delete</el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="1" class="icon-style">
          <el-button
            icon="el-icon-full-screen"
            type="text"
            v-if="isExpandTable === false"
            @click="expendTable"
          ></el-button>
          <el-button
            v-if="isExpandTable === true"
            icon="el-icon-full-screen"
            type="text"
            @click="expendTable"
          ></el-button>
        </el-col>
      </el-row>
      <hr style="margin-top: unset; margin-bottom: unset" />
      <el-row :gutter="12" class="mt-3">
        <el-col :span="18">
          <p class="DateStyles">{{ todayDate }}</p>
          <p class="DayStyles">{{ getDayOfWeek(currentDay) }}</p>
        </el-col>
        <el-col :span="2" style="padding-left: 65px; padding-right: 0px">
          <img
            src="@/assets/img/calculator/Early_Morning_Icon.svg"
            alt="Early_Morning_Icon"
          />
        </el-col>
      </el-row>
      <el-row> </el-row>
      <el-row class="mt-3">
        <div class="icon avatar-uploader">
          <p class="timeStyles">
            {{ hours }} : {{ minutes }} : {{ seconds }} Hrs
          </p>
        </div>
      </el-row>
      <div class="section-wrapper">
        <div class="bottom-right-content">
          <span @click="openSwipes" class="color">view swipes </span>
          <el-button
            v-if="currentSwipeState"
            class="button"
            type="primary"
            size="mini"
            style="background-color: #409eff; color: #ffffff; margin-left: 20px"
            @click="login"
            :disabled="isDashboardRoute"
          >
            <img
              src="@/assets/img/calculator/Sign_In_Icon.svg"
              alt="Sign_In_Icon"
            />
            {{ component.start_button }}
          </el-button>
          <el-button
            v-else
            @click="logout"
            class="button"
            type="primary"
            size="mini"
            style="background-color: #409eff; color: #ffffff"
          >
            <img
              src="@/assets/img/calculator/Sign_In_Icon.svg"
              alt="Sign_In_Icon"
            />
            {{ component.end_button }}
          </el-button>
        </div>
      </div>
    </section>

    <div>
      <el-dialog
        :visible.sync="optionsDialogVisible"
        title="View Swipes"
        :destroy-on-close="true"
        width="35%"
        center
        class="edit-options-popup"
      >
        <el-table
          border
          :data="data1"
          :header-row-class-name="'my-header-row'"
          :header-cell-style="headerCellStyle"
          class="tableSty"
          :show-header="true"
        >
          <el-table-column class="labelSty" label="In">
            <template slot-scope="scope">{{ scope.row.time1 }}</template>
          </el-table-column>
          <el-table-column class="labelSty" label="out">
            <template slot-scope="scope">{{ scope.row.time2 }}</template>
          </el-table-column>
          <el-table-column class="labelSty" label="Duration">
            <template slot-scope="scope">{{ scope.row.duration }}</template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog
        title="Edit component"
        :visible.sync="centerLoginDialogVisible"
        :width="getIsMobile ? '100%' : '60%'"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div v-if="addStatsData" class="main-form">
          <label>Component name</label>
          <el-input
            v-model="addStatsData.component_name"
            placeholder="Enter component name"
            class="mb-1"
          ></el-input>
          <el-table :data="addStatsData.loginsDataDetails" border>
            <el-table-column label="componentinfo">
              <template slot-scope="scope">
                {{ scope.row.component }}
              </template>
            </el-table-column>
            <el-table-column prop="Contact Type" label="Fields" width="250">
              <template slot-scope="scope">
                <el-select v-model="scope.row.field" size="mini" clearable>
                  <el-option
                    v-for="(field, i) of allDateTimeFields"
                    :key="field + i"
                    :value="field.template_key"
                    :label="field.label"
                    :disabled="isFieldDisabled(field)"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="centerLoginDialogVisible = false"
            >Cancel</el-button
          >
          <el-button type="primary" @click="saveEditData">Confirm</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import EntitiesHelper from "@/mixins/EntitiesHelper";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
export default {
  mixins: [userPermissionsHelper, EntitiesHelper, TemplateBuilderHelper],
  components: {},

  props: {
    component: Object,
    index: Number,
    hide_options: Boolean,
    isFromApplicationuser: Boolean,
    customDashboard: Object,
    data: Object,
    entityDataId: String,
  },

  computed: {
    getIsExpandTable() {
      return this.isExpandTable;
    },
    ...mapGetters("entities", [
      "getAllCalendarsData",
      "getAllEntities",
      "getEntityRecordsForTable",
      "getEntitiesDatasByQuery",
    ]),
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("auth", [
      "getDefaultDateFormat",
      "getAuthenticatedUser",
      "getActiveContactType",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("templatesData", [
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityData",
      "getUserTemplateDataUpdateStatus",
    ]),
    ...mapGetters("companyTemplates", ["getSingleCompanyTemplate"]),
      currentSwipeState() {
    if(this.getEntitiesDatasByQuery?.length){
    let toggleButton=[] 
    let logInField = this.componentData?.loginsDataDetails.find( fd => fd.component == 'Login')
let logOutField = this.componentData?.loginsDataDetails.find( fd => fd.component == 'Logout')
let durationField = this.componentData?.loginsDataDetails.find( fd => fd.component == 'Duration')
      if(this.componentData.Days >=1){
        let tableData =this.tableFields.filter(e =>(e.data_table_key))
          let tableName =tableData[0].data_table_key
    this.getEntitiesDatasByQuery.map(eData => {
      let rowData =eData.entityData[logInField.field.split('#')[0]][tableName]
      rowData.map(row=>{
       toggleButton.push({
        time1 : row[logInField.field.split('#')[1]],
        time2 : row[logOutField.field.split('#')[1]],
        duration : row[durationField.field.split('#')[1]]
      })
    })
    });
    if(toggleButton[toggleButton.length-1].time2 == undefined){
      return false;
    }else{
      return true;
    }
      }
      else{


this.getEntitiesDatasByQuery.map(eData => {
  toggleButton.push({
        time1 : eData.entityData[logInField.field.split('#')[0]][logInField.field.split('#')[1]],
        time2 : eData.entityData[logOutField.field.split('#')[0]][logOutField.field.split('#')[1]],
        duration : eData.entityData[durationField.field.split('#')[0]][durationField.field.split('#')[1]]
      })
    })
    if(toggleButton[toggleButton.length-1].time2 == undefined){
      return false;
    }else{
      return true;
    }
  }
}
    else{
      return true;
    }
    },
    getAllEntitiesData() {
      return this.getAllEntities && this.getAllEntities.data
        ? this.getAllEntities.data
        : [];
    },
    isDashboardRoute() {
      return this.$route.path.includes("/custom/dashboard/");
    },
  },
  data() {
    return {
      isList: false,
      showLabel: true,
      isDefalutPosq: true,
      loading: false,
      isLoggedIn: false,
      currentTime: this.getCurrentTime(),
      recording: false,
      recordedTimes: [],
      data1: [],
      data2: [],
      optionsDialogVisible: false,
      todayDate: "",
      isExpandTable: false,
      componentData: {},
      addStatsData: null,
      currentDay: new Date(),
      hours: "",
      minutes: "",
      seconds: "",
      centerLoginDialogVisible: false,
      info: [
        { component: "Login", field: "" },
        { component: "Logout", field: "" },
        { component: "Duration", field: "" },
        { component: "Date", field: "" },
      ],
      allDateTimeFields: [],
      permittedEntities: [],
      entity_data_id: "",
      selectedApplicationUser: "",
      isEntityDashboard: false,
      currentActiveWorkspace: null,
      swipes: {},
      tableKeyIndex: "",
      shouldDisplayInput: false,
      tableFields: [],
      selectedFieldType: "",
      dateTimeFieldObject: null,
      fieldSelected: false,
      selectedFirstField: null,
      EntityFields: [],
      entityField: [],
      userEntityField: {},
    };
  },
  async mounted() {
    this.componentData = JSON.parse(JSON.stringify(this.component));
    this.tableFields = await this.fetchEntityDetails(this.componentData.entity_id,true,false,true);
    let activeWorkspace = this.getActiveContactType?.contact_type?._id;
    this.currentActiveWorkspace = (
      this.getAuthenticatedUser.contact_types || []
    ).find((e) => {
      let id =
        e.contact_type && e.contact_type._id
          ? e.contact_type._id
          : e.contact_type;
      if (id && id == activeWorkspace) {
        return true;
      }
    });
    if (
      this.getAuthenticatedUser &&
      this.getAuthenticatedUser.is_contact &&
      this.getAuthenticatedUser.is_contact == true
    ) {
      this.selectedApplicationUser =
        this.currentActiveWorkspace &&
        this.currentActiveWorkspace.account_data_id
          ? this.currentActiveWorkspace.account_data_id
          : "";
    } else {
      this.selectedApplicationUser = "ALL";
    }
    if (this.$route.name == "entity-custom-dashboard-config") {
      this.isEntityDashboard = true;
    }
    const contactTypeId = this.getActiveContactType?.contact_type?._id;
    const role_id = this.getAuthenticatedUser?.activeRole?.role_id;
    let params = {
      entity_id: this.componentData.entity_id,
      userId: this.getAuthenticatedUser._id,
      // date: new Date(),
    };
    if (contactTypeId) {
      params.workSpace = contactTypeId;
    } else if (role_id) {
      params.role = role_id;
    }
    await this.$store.dispatch("entities/fetchEntitiesDataByQuery", params);
    this.loading = false;
    this.getDateTime();
    this.intervalId = setInterval(this.updateClock, 1000);
    this.updateTime();
    setInterval(this.updateTime, 1000);
    if(this.getEntitiesDatasByQuery.length){
this.data2 = []

let logInField = this.componentData.loginsDataDetails.find( fd => fd.component == 'Login')
let logOutField = this.componentData.loginsDataDetails.find( fd => fd.component == 'Logout')
let durationField = this.componentData.loginsDataDetails.find( fd => fd.component == 'Duration')
let dateField = this.componentData.loginsDataDetails.find( fd => fd.component == 'Date')
    if(this.componentData.Days){
    let tableData =this.tableFields.filter(e =>(e.data_table_key))
          let tableName =tableData[0].data_table_key
    this.getEntitiesDatasByQuery.map(eData => {
      let rowData =eData.entityData[logInField.field.split('#')[0]][tableName]
      rowData.map(row=>{
       this.data2.push({
        time1 : row[logInField.field.split('#')[1]],
        time2 : row[logOutField.field.split('#')[1]],
        duration : row[durationField.field.split('#')[1]],
        date : row[dateField.field.split('#')[1]],
      })
    })
    });
  }
  else{
    this.getEntitiesDatasByQuery.map(eData => {
      this.data2.push({
        time1 : eData.entityData[logInField.field.split('#')[0]][logInField.field.split('#')[1]],
        time2 : eData.entityData[logOutField.field.split('#')[0]][logOutField.field.split('#')[1]],
        duration : eData.entityData[durationField.field.split('#')[0]][durationField.field.split('#')[1]],
        date :  eData.entityData[dateField.field.split('#')[0]][dateField.field.split('#')[1]],
        
      })
    })
  }    
 }
 
 if(this.componentData.user_field){
  this.EntityFields = await this.fetchEntityDetails( this.componentData.entity_id,true,false,true);
  this.userEntityField =this.componentData.user_field.split('#')[1]
 }
  this.entityField =this.EntityFields.find(ef => ef.inputType =="ENTITY")
  
  },
  methods: {
    expendTable() {
      this.$emit("expendTable", this.index);
    },
    expandTablewhenHide() {
      this.isExpandTable = false;
      this.$emit("expendTable", this.index);
    },
    deleteComponent() {
      this.$confirm("Are you sure to delete the Calendar?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$emit("deleteComponent", this.index);
      });
    },
    async getLoginEntityDateFields() {
      if (this.addStatsData?.entity_id) {
        this.allEntityFields = await this.fetchEntityDetails(
          this.addStatsData.entity_id,
          true,
          false,true
        );
        this.allDateTimeFields = this.allEntityFields.filter(
          (e) => e.input_type == "TIME" || e.input_type == "DATE"
        );
      }
    },
    editDialogBox() {
      this.addStatsData = JSON.parse(JSON.stringify(this.componentData));
      this.getLoginEntityDateFields();
      this.centerLoginDialogVisible = true;
    },
    saveEditData() {
      this.componentData = { ...this.addStatsData };
      this.$emit("tableUpdate", {
        index: this.index,
        data: this.componentData,
        component: "LOGINS",
      });
      this.centerLoginDialogVisible = false;
      this.$notify.info({
        title: "Info",
        message: "Please Click on Save to make Edit Changes",
      });
    },
    async login() {
      this.data1 = [];
      let obj = {
        time1: moment().format("HH:mm:ss"),
        date: moment().format("YYYY-MM-DD"),
      };
      this.data2.push(obj);
      const contactTypeId = this.getActiveContactType?.contact_type?._id;
      const role_id = this.getAuthenticatedUser?.activeRole?.role_id;
      let details = {
        userEmail: this.getAuthenticatedUser.email,
        userId: this.getAuthenticatedUser._id,
      };
      if (contactTypeId) {
        details.workSpace = contactTypeId;
      } else if (role_id) {
        details.role = role_id;
      }

      let login = {};
      let toDate = {};
      let obj2 = {};
      let loginTemplatedId = "";
      let dateTemplateId = "";
      let tableObj={}
      let tableArray=[]
     
      let dateField = this.componentData.loginsDataDetails.find( fd => fd.component == 'Date')
      let logInField = this.componentData.loginsDataDetails.find( fd => fd.component == 'Login')
      let logOutField = this.componentData.loginsDataDetails.find( fd => fd.component == 'Logout')
      let durationField = this.componentData.loginsDataDetails.find( fd => fd.component == 'Duration')
      this.tableKeyIndex = (Math.random() + 1).toString(36).substring(7);
      tableObj["keyIndex"] =this.tableKeyIndex
      let template_id =logInField.field.split('#')[0]
  if(this.componentData.Days){
        let tableData =this.tableFields.filter(e =>(e.data_table_key))
          tableObj[logInField.field.split('#')[1]] = obj.time1
          tableObj[dateField.field.split('#')[1]] = obj.date
          tableObj[logOutField.field.split('#')[1] + '/autoLogout'] =moment(obj.time1, 'HH:mm:ss').add(this.componentData.auto_logout, 'hours').format('HH:mm:ss');
          let outTime =moment(obj.time1, 'HH:mm:ss').add(this.componentData.auto_logout, 'hours').format('HH:mm:ss');
          const momentObj1 = moment(obj.time1, 'HH:mm:ss');
          const momentObj2 = moment(outTime, 'HH:mm:ss');
          let duration=moment.utc(momentObj2.diff(momentObj1));
          tableObj[durationField.field.split('#')[1] + '/autoDuration'] = duration.format('HH:mm:ss');
          tableArray.push(tableObj)
              obj2[tableData[0].data_table_key]=tableArray

        }
        else{

          this.componentData.loginsDataDetails.map((x) => {
        let template_id = x.field.split("#")[0];
        obj2[x.field.split("#")[1]] = "";
        if (x.component == "Login") {
          obj2[x.field.split("#")[1]] = obj.time1;
          login[x.field.split("#")[1]]= obj.time1;
          loginTemplatedId = x.field.split("#")[0];
        }
        else if (x.component == "Date") {
          obj2[x.field.split("#")[1]] = obj.date;
          toDate[x.field.split("#")[1]] = obj.date;
          dateTemplateId = x.field.split("#")[0];
      }
      if(this.componentData.auto_logout){
        let outTime=''
        if(x.component == "Logout"){
          obj2[x.field.split("#")[1] + '/autoLogout'] = moment(obj.time1, 'HH:mm:ss').add(this.componentData.auto_logout, 'hours').format('HH:mm:ss');
          toDate[x.field.split("#")[1] + '/autoLogout'] = moment(obj.time1, 'HH:mm:ss').add(this.componentData.auto_logout, 'hours').format('HH:mm:ss');
          dateTemplateId = x.field.split("#")[0];
        }
        if(x.component == "Duration"){
          outTime =moment(obj.time1, 'HH:mm:ss').add(this.componentData.auto_logout, 'hours').format('HH:mm:ss');
          const momentObj1 = moment(obj.time1, 'HH:mm:ss');
    const momentObj2 = moment(outTime, 'HH:mm:ss');
           let duration=moment.utc(momentObj2.diff(momentObj1));
           obj2[x.field.split('#')[1]+ '/autoDuration'] =duration.format('HH:mm:ss');
        } 
      }
        return template_id;
      });
      let postData = Object.keys(obj2);
      postData.map((x) => {
        if((obj2[x] == "")) {
          delete obj2[x];
        }
      });
    }
    if(this.componentData.user_field){
        obj2[this.userEntityField ] =this.selectedApplicationUser
        obj2[this.userEntityField +'/name'] =this.getAuthenticatedUser.first_name +' '+ this.getAuthenticatedUser.last_name
      }
      let dateParams = {
        entity_id: this.componentData.entity_id,
        userId: this.getAuthenticatedUser._id,
        date: new Date(),
      };
      if (contactTypeId) {
        dateParams.workSpace = contactTypeId;
      } else if (role_id) {
        dateParams.role = role_id;
      }
      await this.$store.dispatch(
        "entities/fetchEntitiesDataByQuery",
        dateParams
      );
      if (this.componentData.Days) {
        let entityDataByQuery = this.getEntitiesDatasByQuery;
        let latestRecord = entityDataByQuery[entityDataByQuery.length - 1];
        let templateData = await this.getTemplateData(template_id);
        let fields = this.getTemplateFields(templateData);
        let form = 
        this.mapDefaultValues(fields,obj2, form, false);
        this.applyFormRules(obj2, fields, templateData.rules);
        if (latestRecord == null) {
          let recordDate = moment();
          let endDate = recordDate
            .add(this.componentData.Days, "days")
            .format("YYYY-MM-DD");
          if (obj.date < endDate) {
            let payload = {
              template_data: form,
              entity_id: this.componentData.entity_id,
              template_id: template_id,
              template_completion_status: true,
              loginDetails: details,
            };
            this.loading = true;
            await this.$store.dispatch(
              "templatesData/createEntityDataByTemplateData",
              payload
            );
          }
        }
        else{
          let recordDate =  moment();
           let endDate = recordDate.add(this.componentData.Days, 'days').format('YYYY-MM-DD');
          if(obj.date<endDate)
          {
            let dataOfPayload ={}
          if(this.componentData.user_field){
            dataOfPayload = {[Object.keys(obj2)[0]] : [...latestRecord.entityData[template_id][Object.keys(obj2)[0]],...tableArray],  [this.userEntityField ] : this.selectedApplicationUser,
            [this.userEntityField +'/name'] : this.getAuthenticatedUser.first_name +' '+ this.getAuthenticatedUser.last_name
             }
             }
            else{
          dataOfPayload = { [Object.keys(obj2)[0]] : [...latestRecord.entityData[template_id][Object.keys(obj2)[0]],...tableArray]
                     }
             }
        let templateData = await this.getTemplateData(template_id);
        let fields = this.getTemplateFields(templateData);
        let form = 
        this.mapDefaultValues(fields,dataOfPayload, form, false)
          let update = {
          template_data: form,
          
          // {
          //   [Object.keys(obj2)[0]] : [...latestRecord.entityData[template_id][Object.keys(obj2)[0]],...tableArray]
          // },
          entity_data_id:latestRecord._id,
          entity_id: this.componentData.entity_id,
          template_id: template_id,  
          template_completion_status: true,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          update
        );
      }
      else{
        let templateData = await this.getTemplateData(template_id);
        let fields = this.getTemplateFields(templateData);
        let form = 
        this.mapDefaultValues(fields,obj2, form, false)
        let payload = {
          template_data: form,
          entity_id: this.componentData.entity_id,
          template_id: template_id,
          template_completion_status: true,
          loginDetails: details,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/createEntityDataByTemplateData",
          payload
        );

      }
        }
      } else if (loginTemplatedId == dateTemplateId) {
        let templateData = await this.getTemplateData(loginTemplatedId);
        let fields = this.getTemplateFields(templateData);
        let form = this.applyFormRules(obj2, fields, templateData.rules);
        let payload = {
          template_data: form,
          entity_id: this.componentData.entity_id,
          template_id: loginTemplatedId,
          template_completion_status: true,
          loginDetails: details,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/createEntityDataByTemplateData",
          payload
        );
      } else {
        let templateData = await this.getTemplateData(loginTemplatedId);
        let fields = this.getTemplateFields(templateData);
        let form = this.applyFormRules(login, fields, templateData.rules);
        let payload = {
          template_data: form,
          entity_id: this.componentData.entity_id,
          template_id: loginTemplatedId,
          template_completion_status: true,
          entity_data_id: null,
          loginDetails: details,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/createEntityDataByTemplateData",
          payload
        );
        let update = {
          template_data: toDate,
          entity_data_id: this.getNewEntityData._id,
          entity_id: this.componentData.entity_id,
          template_id: dateTemplateId,
          template_completion_status: true,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          update
        );
      }
      this.entity_data_id = this.getNewEntityData._id;
      this.$notify({
        title: "Success",
        message: this.componentData.start_button,
        type: "success",
      });
      if (this.componentData.selectedEntityRelationalData) {
        let data = {
          parent_entity_id: this.getActiveContactType?.contact_type?._id,
          child_entity_id: this.componentData.entity_id,
          parent_entity_data_id: this.getActiveContactType?.account_data_id,
          child_entity_data_ids: [this.getNewEntityData._id],
        };
        await this.$store.dispatch(
          "entityRelationships/assignEntityRelationshipData",
          data
        ); 
      }
     
       this.contactTypeId = this.getActiveContactType?.contact_type?._id;
      this.role_id =this.getAuthenticatedUser?.activeRole?.role_id;
      let params = {
        entity_id: this.componentData.entity_id,
        userId: this.getAuthenticatedUser._id,
        date: new Date(),
      };
      if (contactTypeId) {
        params.workSpace = this.contactTypeId;
      } else if (role_id) {
        params.role = this.role_id;
      }
      await this.$store.dispatch("entities/fetchEntitiesDataByQuery", params);
      this.loading = false;
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    async getTemplateData(templateId) {
      await this.$store.dispatch(
        "companyTemplates/fetchSingleCompanyTemplate",
        templateId
      );
      console.log("templateId",templateId,this.getSingleCompanyTemplate)
      return this.getSingleCompanyTemplate;
    },
    async logout() {
      this.data2["time2"] = "";
      this.data2["duration"] = "";
      this.data2[this.data2.length - 1].time2 = moment().format("HH:mm:ss");
      let inTime = moment(this.data2[this.data2.length - 1].time1, "HH:mm:ss");
      let outTime = moment(this.data2[this.data2.length - 1].time2, "HH:mm:ss");
      this.data2[this.data2.length - 1].duration = moment
        .utc(outTime.diff(inTime))
        .format("HH:mm:ss");
      this.form = [...this.data2];
      let logout = {};
      let duration = {};
      let login = {};
      let toDate = {};
      let obj2 = {};
      let logoutTemplateId = "";
      let durationTemplateId = "";
      let loginTemplateId="";
      let dateTemplateId ='';
      let tableObj={}
      let tableArray=[]
let logInField = this.componentData.loginsDataDetails.find( fd => fd.component == 'Login')
let logOutField = this.componentData.loginsDataDetails.find( fd => fd.component == 'Logout')
let durationField = this.componentData.loginsDataDetails.find( fd => fd.component == 'Duration')
 let template_id =logInField.field.split('#')[0]
  if(this.componentData.Days){
   let tableData =this.tableFields.filter(e =>(e.data_table_key))
         tableObj[logOutField.field.split('#')[1]] =this.data2[this.data2.length - 1].time2;
         tableObj[durationField.field.split('#')[1]] = this.data2[this.data2.length - 1].duration;
         tableArray.push(tableObj)
          obj2[tableData[0].data_table_key]=tableArray
        }
        else{
                this.componentData.loginsDataDetails.map((x) => {
              tableObj["keyIndex"]=this.tableKeyIndex
                obj2[x.field.split("#")[1]] = "";
                  if (x.component == "Login") {
                    obj2[x.field.split("#")[1]] = this.data2[this.data2.length - 1].time1;
                    login[x.field.split("#")[1]] = this.data2[this.data2.length - 1].time1;
                    loginTemplateId =x.field.split("#")[0]
                  } 
                  else if (x.component == "Logout") {
                    obj2[x.field.split("#")[1]] = this.data2[this.data2.length - 1].time2;
                    logout[x.field.split("#")[1]] = this.data2[this.data2.length - 1].time2;
                    logoutTemplateId =x.field.split("#")[0]
                  } 
                  else if (x.component == "Date") {
                    obj2[x.field.split("#")[1]] = this.data2[this.data2.length - 1].date;
                    toDate[x.field.split("#")[1]] = this.data2[this.data2.length - 1].date;
                    dateTemplateId = x.field.split("#")[0]
                  } 
                  else if  (x.component == "Duration"){
                    obj2[x.field.split("#")[1]] =
                      this.data2[this.data2.length - 1].duration;
                    duration[x.field.split("#")[1]] =
                      this.data2[this.data2.length - 1].duration;
                      durationTemplateId = x.field.split("#")[0]
                    
                  }
                  return template_id;
              
                });
              }
               
      if(logoutTemplateId == loginTemplateId){
        Object.assign( logout,login)
      }
      if (logoutTemplateId == dateTemplateId) {
        Object.assign(logout, toDate);
      }
      if (durationTemplateId == loginTemplateId) {
        Object.assign(duration, login);
      }
      if (durationTemplateId == dateTemplateId) {
        Object.assign(duration, toDate);
      }
      if (this.componentData.user_field) {
        obj2[this.userEntityField] = this.selectedApplicationUser;
        obj2[this.userEntityField + "/name"] =
          this.getAuthenticatedUser.first_name +
          " " +
          this.getAuthenticatedUser.last_name;
      }
      if (this.componentData.Days) {
        let entityDataByQuery = this.getEntitiesDatasByQuery;
   let latestRecord = entityDataByQuery[entityDataByQuery.length - 1];
   let preData =latestRecord.entityData[template_id][Object.keys(obj2)[0]]
   const newArray = [...preData.slice(0, -1), { ...preData[preData.length - 1], ...tableObj }];
let dataOfPayload ={}
if(this.componentData.user_field){
 dataOfPayload ={ [Object.keys(obj2)[0]] : [...newArray], [ this.userEntityField ] :this.selectedApplicationUser,
  [this.userEntityField +'/name'] : this.getAuthenticatedUser.first_name +' '+ this.getAuthenticatedUser.last_name
  }
}else{
  dataOfPayload ={ [Object.keys(obj2)[0]] : [...newArray],}
} 
let templateData = await this.getTemplateData(template_id);
let fields = this.getTemplateFields(templateData);
 let form=  this.mapDefaultValues(fields,dataOfPayload, form, false)
        let payload = {
          template_data: form,
          entity_data_id: this.getNewEntityData._id,
          entity_id: this.componentData.entity_id,
          template_id: template_id,
          template_completion_status: true,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          payload
        );
      } else if (durationTemplateId == logoutTemplateId) {
        let templateData = await this.getTemplateData(durationTemplateId);
        let fields = this.getTemplateFields(templateData);
        let form = this.applyFormRules(obj2, fields, templateData.rules);
        let payload = {
          template_data: form,
          entity_data_id: this.getNewEntityData._id,
          entity_id: this.componentData.entity_id,
          template_id: logoutTemplateId,
          template_completion_status: true,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          payload
        );
      } else {
        let payload = {
          template_data: logout,
          entity_data_id: this.getNewEntityData._id,
          entity_id: this.componentData.entity_id,
          template_id: logoutTemplateId,
          template_completion_status: true,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          payload
        );
        let update = {
          template_data: duration,
          entity_data_id: this.getNewEntityData._id,
          entity_id: this.componentData.entity_id,
          template_id: durationTemplateId,
          template_completion_status: true,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          update
        );
      }
      this.$notify({
        title: "Success",
        message: this.componentData.end_button,
        type: "success",
      });
      this.contactTypeId = this.getActiveContactType?.contact_type?._id;
      this.role_id = this.getAuthenticatedUser?.activeRole?.role_id;
      let params = {
        entity_id: this.componentData.entity_id,
        userId: this.getAuthenticatedUser._id,
        date: new Date(),
      };
      if (this.contactTypeId) {
        params.workSpace = this.contactTypeId;
      } else if (this.role_id) {
        params.role = this.role_id;
      }
      await this.$store.dispatch("entities/fetchEntitiesDataByQuery", params);
      this.loading = false;
    },
    toggleLoginState() {
      this.isLoggedIn = !this.isLoggedIn;
      this.recordedTimes.push(this.getCurrentTime());
      this.getDifference();
    },
    getDateTime() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      this.todayDate = `${day}/${month}/${year}`;
    },
    updateTime() {
      const now = new Date();
      this.hours = now.getHours().toString().padStart(2, "0");
      this.minutes = now.getMinutes().toString().padStart(2, "0");
      this.seconds = now.getSeconds().toString().padStart(2, "0");
    },
    getCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const seconds = now.getSeconds().toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    },
    getDayOfWeek(date) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayIndex = date.getDay();
      return daysOfWeek[dayIndex];
    },
    updateClock() {
      this.currentTime = this.getCurrentTime();
    },
    async openSwipes() {
      const contactTypeId = this.getActiveContactType?.contact_type?._id;
      const role_id = this.getAuthenticatedUser?.activeRole?.role_id;
      let params = {
        entity_id: this.componentData.entity_id,
        userId: this.getAuthenticatedUser._id,
        date: new Date(),
      };
      if (contactTypeId) {
        params.workSpace = this.contactTypeId;
      } else if (role_id) {
        params.role = this.role_id;
      }
      await this.$store.dispatch("entities/fetchEntitiesDataByQuery", params);
this.data1 = []
if(this.getEntitiesDatasByQuery?.length){
let logInField = this.componentData.loginsDataDetails.find( fd => fd.component == 'Login')
let logOutField = this.componentData.loginsDataDetails.find( fd => fd.component == 'Logout')
let durationField = this.componentData.loginsDataDetails.find( fd => fd.component == 'Duration')
    if(this.componentData.Days){
    let tableData =this.tableFields.filter(e =>(e.data_table_key))
          let tableName =tableData[0].data_table_key
    this.getEntitiesDatasByQuery.map(eData => {
      let rowData =eData.entityData[logInField.field.split('#')[0]][tableName]
      rowData.map(row=>{
       this.data1.push({
        time1 : row[logInField.field.split('#')[1]],
        time2 : row[logOutField.field.split('#')[1]],
        duration : row[durationField.field.split('#')[1]]
      })
    })
    });
  }
  else{
    this.getEntitiesDatasByQuery.map(eData => {
      this.data1.push({
        time1 : eData.entityData[logInField.field.split('#')[0]][logInField.field.split('#')[1]],
        time2 : eData.entityData[logOutField.field.split('#')[0]][logOutField.field.split('#')[1]],
        duration : eData.entityData[durationField.field.split('#')[0]][durationField.field.split('#')[1]]
      })
    })
  } 
}   
  this.optionsDialogVisible = true;
    },
    headerCellStyle() {
      return {
        backgroundColor: "#F2F6FC",
        color: "#606266",
        fontWeight: "bold",
      };
    },
    isFieldDisabled(field) {
      let fieldSelected = false;
      let selectedFirstField;
      this.addStatsData.loginsDataDetails.map((e) => {
        if (e.field) {
          fieldSelected = true;
          selectedFirstField = e;
        }
      });
      if (!fieldSelected) {
        this.selectedFieldType = "";
        this.dateTimeFieldObject = null;
        return false;
      }
      if (!this.dateTimeFieldObject) {
        let key = selectedFirstField.field;
        this.dateTimeFieldObject = this.allDateTimeFields.find(
          (e) => e.template_key == key
        );
        if (
          this.dateTimeFieldObject &&
          this.dateTimeFieldObject.isDataTableField
        ) {
          this.selectedFieldType = "data_table";
          this.shouldDisplayInput = true;
        } else {
          this.selectedFieldType = "normal";
          this.shouldDisplayInput = false;
        }
      }
      if (!this.dateTimeFieldObject) {
        return false;
      }
      if (
        this.selectedFieldType == "data_table" &&
        field.isDataTableField &&
        this.addStatsData.loginsDataDetails.findIndex(
          (ex) => ex.field == field.template_key
        ) == -1
      ) {
        return false;
      } else if (
        this.selectedFieldType == "normal" &&
        !field.isDataTableField &&
        this.addStatsData.loginsDataDetails.findIndex(
          (ex) => ex.field == field.template_key
        ) == -1
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style lang="scss">
.avatar-uploader {
  height: 50px;
  justify-content: left !important;
  text-align: none;
  border-radius: 8px;
  width: 30%;
  border-width: 2px;
  border-style: initial;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.sign_img {
  height: 20px;
  width: 100%;
}
.color {
  color: rgba(29, 160, 221);
  justify-content: right !important;
  font-size: 16px;
}
.button {
  border-radius: 3px;
  justify-content: right !important;
  font-size: medium;
  font-variant-position: sub;
  height: 45px;
}
.timeStyles {
  font-size: 18px;
  color: rgb(29, 160, 221);
  font-style: normal;
  padding-left: 20px;
}
.DateStyles {
  font-size: 15px;
  color: black;
  font-style: normal;
  justify-content: left !important;
  padding-left: 20px;
}
.DayStyles {
  font-size: 15px;
  color: rgb(85, 83, 83);
  font-style: normal;
  justify-content: left !important;
  padding-left: 20px;
}
.section-wrapper {
  position: relative;
}

.bottom-right-content {
  position: absolute;
  top: 50px;
  right: 45px;
}
</style>
